<template>
  <b-container>
    <b-row>
      <b-col md="8">
        <b-card>
          <b-row class="my-1">
            <b-col md="8">
              <p class="font-medium-5 font-weight-bolder">#{{ form.code }}</p>
            </b-col>
            <b-col md="4" class="text-right">
              <b-badge variant="light-dark" v-if="form.status === 0">{{
                form.status_name
              }}</b-badge>
              <b-badge
                variant="light-warning"
                v-else-if="form.status === 1 || form.status === 2"
                >{{ form.status_name }}</b-badge
              >
              <b-badge
                variant="light-primary"
                v-else-if="form.status === 3 || form.status === 4"
                >{{ form.status_name }}</b-badge
              >
              <b-badge variant="light-success" v-else-if="form.status === 5">{{
                form.status_name
              }}</b-badge>
              <b-badge variant="light-danger" v-else-if="form.status === 9">{{
                form.status_name
              }}</b-badge>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Jenis Sewa" label-for="type">
                <b-form-input id="type" v-model="form.driverName" readonly />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Total Unit" label-for="end-date">
                <b-form-input
                  id="end-date"
                  v-model="quotation.total_unit"
                  readonly
                /> </b-form-group
            ></b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Periode Sewa" label-for="end-date">
                <b-form-input
                  id="end-date"
                  v-model="form.periodName"
                  readonly
                /> </b-form-group
            ></b-col>
            <b-col md="6" v-if="quotation.period">
              <b-form-group label="Lama Sewa (bulan)" label-for="end-date">
                <b-form-input
                  id="end-date"
                  v-model="quotation.duration"
                  readonly
                /> </b-form-group
            ></b-col>
          </b-row>
          <b-row v-if="form.status == 0">
            <b-col md="6">
              <b-form-group label="Tanggal Mulai" label-for="end-date">
                <flat-pickr
                  v-model="form.startDate"
                  class="form-control"
                  :config="{
                    dateFormat: 'd-m-Y',
                    minDate: 'today',
                  }"
                /> </b-form-group
            ></b-col>
            <b-col md="6">
              <b-form-group label="Tanggal Berakhir" label-for="end-date">
                <flat-pickr
                  v-model="form.endDate"
                  class="form-control"
                  :config="{
                    dateFormat: 'd-m-Y',
                    minDate: form.startDate,
                  }"
                /> </b-form-group
            ></b-col>
          </b-row>
          <b-row v-if="form.status > 0">
            <b-col md="6">
              <b-form-group label="Tanggal Mulai" label-for="end-date">
                <b-form-input
                  id="end-date"
                  v-model="form.startDate"
                  readonly /></b-form-group
            ></b-col>
            <b-col md="6">
              <b-form-group label="Tanggal Berakhir" label-for="end-date">
                <b-form-input
                  id="end-date"
                  v-model="form.endDate"
                  readonly /></b-form-group
            ></b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Nilai Kontrak" label-for="price">
                <b-form-input id="price" v-model="form.price" readonly />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Alamat Pengiriman"
                label-for="delivery-address"
              >
                <b-form-textarea
                  id="delivery-address"
                  placeholder="Alamat Pengiriman"
                  v-model="form.deliveryAddress"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="form.pickingAddress != null">
            <b-col>
              <b-form-group
                label="Alamat Pengambilan"
                label-for="picking-address"
              >
                <b-form-textarea
                  id="picking-address"
                  placeholder="Alamat Pengambilan"
                  v-model="form.pickingAddress"
                  readonly
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <label class="font-weight-bolder text-primary mb-1" for="unit"
            >Unit</label
          >
          <b-row v-if="unit.id != null">
            <b-col md="8">
              <b-media vertical-align="center">
                <template #aside>
                  <b-img
                    :src="unit.image"
                    blank-color="#fff"
                    width="150"
                    alt="placeholder"
                  />
                </template>
                <h4 class="media-heading mt-1">
                  {{ unit.brand }} - {{ unit.name }}
                </h4>
                <b-card-text class="mb-1">
                  {{ unit.code }}
                </b-card-text>
                <b-link :href="'/unit/' + unit.id"> Lihat Detail </b-link>
              </b-media>
            </b-col>
            <b-col md="4">
              <label for="" class="mt-1">Kilometer Awal</label>
              <h4 class="mt-25">
                {{ form.mileage + " km" }}
              </h4>
            </b-col>
          </b-row>
          <b-row v-if="unit.id != null">
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-primary"
                class="mt-1"
                block
                v-b-modal.modal-unit
              >
                Perbaharui
              </b-button>
            </b-col>
          </b-row>
          <b-row v-if="unit.id == null">
            <b-col md="8">
              <p class="mt-1">Belum ada unit yang dipilih untuk kontrak ini.</p>
            </b-col>
            <b-col md="4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-success"
                class="float-right"
                v-b-modal.modal-unit
              >
                Tambah Unit
              </b-button>
            </b-col>
          </b-row>
        </b-card>
        <b-row class="mb-3">
          <b-col v-if="form.status === 0">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              block
              @click="settleContract()"
            >
              Proses
            </b-button>
          </b-col>
          <b-col v-if="form.status === 2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              block
              @click="confirmPayment()"
            >
              Konfirmasi Pembayaran
            </b-button>
          </b-col>
          <b-col v-if="form.status === 3">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              block
              v-b-modal.modal-process
            >
              Proses
            </b-button>
          </b-col>
          <b-col v-if="form.status < 5">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="danger"
              block
              v-b-modal.modal-cancel
            >
              Batalkan
            </b-button>
          </b-col>
          <b-col v-if="form.status === 5">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              block
              @click="sendReminder()"
            >
              Kirim Pengingat
            </b-button>
          </b-col>
          <b-col v-if="form.status === 5">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="danger"
              block
              v-b-modal.modal-finish
            >
              Selesai
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="4">
        <b-card v-if="form.quotationId != null">
          <b-row>
            <b-col md="12" class="mb-1">
              <label for="details" class="text-primary font-weight-bold"
                >Katalog</label
              >
            </b-col>
            <b-col md="12">
              <b-row>
                <b-col md="4"> Kode </b-col>
                <b-col md="8" class="text-right font-weight-bolder">
                  {{ catalog.code }}
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col md="4"> Nama </b-col>
                <b-col md="8" class="text-right font-weight-bolder">
                  {{ catalog.name }}
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col md="4"> Kategori </b-col>
                <b-col md="8" class="text-right font-weight-bolder">
                  {{ catalog.category }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <label class="font-weight-bolder text-primary mb-1" for="product"
            >Detail Pelanggan</label
          >
          <b-row>
            <b-col md="4">Nama</b-col>
            <b-col md="8" class="text-right font-weight-bolder"
              >{{ customer.name }}
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col md="4">No Telp</b-col>
            <b-col md="8" class="text-right font-weight-bolder">
              {{ customer.phone }}
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col md="4">Email</b-col>
            <b-col md="8" class="text-right font-weight-bolder"
              >{{ customer.email }}
            </b-col>
          </b-row>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            class="mt-3"
            block
          >
            Lihat Detail
          </b-button>
        </b-card>
        <b-card>
          <label class="font-weight-bolder text-primary" for="product"
            >Dokumen</label
          >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-dark"
            block
            class="my-1 mb-2 py-2"
            v-if="form.document != null"
            @click="openFile()"
          >
            <feather-icon icon="FileIcon" class="mr-1" />
            <span class="align-middle">{{ form.document }}</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            block
            v-b-modal.modal-document
            class="my-1 mb-2"
          >
            Upload Dokumen
          </b-button>
          <hr v-if="form.status >= 1" />
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            block
            v-b-modal.modal-photo-payment
            class="my-1 mt-2"
            v-if="form.status >= 1"
          >
            Lihat Bukti Pembayaran
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            block
            v-b-modal.modal-photo-receive
            class="my-1"
            v-if="form.status >= 4"
          >
            Lihat Bukti Penerimaan
          </b-button>
        </b-card>
        <b-card v-if="form.status >= 3">
          <b-row>
            <b-col md="12" class="mb-1">
              <label for="details" class="text-primary font-weight-bold"
                >Info Pengiriman</label
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6"> Tanggal Pengiriman </b-col>
            <b-col md="6" class="text-right font-weight-bolder">
              {{ form.deliveryDate }}
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col md="6"> Nama Petugas</b-col>
            <b-col md="6" class="text-right font-weight-bolder">
              {{ officer.name }}
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col md="6"> No Telp Petugas</b-col>
            <b-col md="6" class="text-right font-weight-bolder">
              {{ officer.phone }}
            </b-col>
          </b-row>
        </b-card>
        <b-card v-if="logs.length != 0">
          <label class="font-weight-bolder text-primary mb-2" for="product"
            >Riwayat</label
          >
          <app-timeline>
            <app-timeline-item
              v-for="(log, index) in logs"
              :key="index"
              :title="log.description"
              subtitle=""
              :time="moment(log.created_at)"
              :variant="log.variant"
            />
          </app-timeline>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modal-unit"
      title="Unit"
      ok-title="Kirim"
      cancel-variant="outline-secondary"
      centered
      hide-footer
    >
      <validation-observer ref="loginValidation">
        <b-form ref="form" @submit.prevent>
          <b-row>
            <b-col>
              <b-form-group label="Kategori" label-for="category">
                <b-form-select
                  id="category"
                  v-model="selectedCategory"
                  :options="optionsCategory"
                  required
                />
                <!-- Selected: <strong>{{ form.selectedCategory }}</strong> -->
              </b-form-group>
              <b-form-group label="Unit" label-for="unit">
                <v-select
                  id="unit"
                  v-model="update.unit"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="form.optionsUnit"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!update.unit"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <!-- Selected: <strong>{{ form.selectedProduct }}</strong> -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Kilometer" label-for="offering">
                <cleave
                  id="offering"
                  placeholder="Kilometer"
                  v-model="update.mileage"
                  class="form-control"
                  :raw="false"
                  :options="options.number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button
                class="mt-1 mb-2"
                type="submit"
                variant="success"
                block
                @click="storeUnit()"
              >
                Tambahkan Unit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      id="modal-document"
      title="Upload Dokumen"
      ok-title="Kirim"
      cancel-variant="outline-secondary"
      centered
      hide-footer
    >
      <validation-observer ref="loginValidation">
        <b-form ref="form" @submit.prevent>
          <b-row>
            <b-col>
              <b-form-group label-for="File">
                <b-form-file
                  v-model="file"
                  placeholder="Upload file disini..."
                  drop-placeholder="Upload file disini..."
                  id="extension"
                  accept=".pdf"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button
                class="mt-1 mb-2"
                type="submit"
                variant="success"
                block
                @click="storeDocument()"
              >
                Kirim
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      id="modal-process"
      title="Info Petugas"
      ok-title="Kirim"
      cancel-variant="outline-secondary"
      centered
      hide-footer
    >
      <validation-observer ref="loginValidation">
        <b-form ref="form" @submit.prevent>
          <b-row>
            <b-col>
              <b-form-group
                label="Tanggal Pengiriman"
                label-for="delivery_date"
              >
                <flat-pickr
                  id="delivery_date"
                  v-model="officer.deliveryDate"
                  class="form-control"
                  :config="{
                    dateFormat: 'd-m-Y',
                    minDate: new Date().fp_incr(1),
                  }"
                  placeholder="Tanggal Pengiriman"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Nama Petugas" label-for="officer">
                <b-form-input
                  id="officer"
                  type="text"
                  v-model="officer.name"
                  placeholder="Nama Petugas"
                  required="required"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="No Telp" label-for="officer-phone">
                <b-form-input
                  id="officer-phone"
                  name="officer-phone"
                  type="text"
                  v-model="officer.phone"
                  placeholder="No Telp"
                  required="required"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-button
                class="mt-1 mb-2"
                type="submit"
                variant="success"
                block
                @click="process()"
              >
                Proses
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      id="modal-photo-payment"
      ok-only
      ok-title="close"
      centered
      size="lg"
      title="Foto"
      class="text-center"
    >
      <b-img
        id="photo"
        :src="form.image.payment"
        width="1000px"
        fluid
        alt="trouble photo"
      />
    </b-modal>
    <b-modal
      id="modal-photo-receive"
      ok-only
      ok-title="close"
      centered
      size="lg"
      title="Foto"
      class="text-center"
    >
      <b-img
        id="photo"
        :src="form.image.receive"
        width="1000px"
        fluid
        alt="trouble photo"
      />
    </b-modal>
    <b-modal
      id="modal-cancel"
      title="Pembatalan"
      ok-title="Kirim"
      cancel-variant="outline-secondary"
      centered
      hide-footer
    >
      <validation-observer ref="loginValidation">
        <b-form ref="form" @submit.prevent>
          <b-form-group
            :state="errors"
            label="Alasan Pembatalan"
            label-for="cancel"
            invalid-feedback="Alasan pembatalan tidak boleh kosong"
          >
            <validation-provider
              #default="{ errors }"
              name="cancel"
              rules="required"
            >
              <b-form-textarea
                id="cancel"
                name="cancel"
                type="text"
                v-model="form.cancelReason"
                :state="errors.length > 0 ? false : null"
                placeholder="Alasan Pembatalan"
                required="required"
              />
              <small class="text-danger">{{ errors[0] || errMessage }}</small>
            </validation-provider>
          </b-form-group>

          <b-row>
            <b-col>
              <b-button
                class="mt-1 mb-2"
                type="submit"
                variant="danger"
                block
                @click="cancel()"
              >
                Kirim
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-finish"
      title="Penyelesaian Kontrak"
      ok-title="Kirim"
      cancel-variant="outline-secondary"
      centered
      hide-footer
    >
      <validation-observer ref="loginValidation">
        <b-form ref="form" @submit.prevent>
          <b-form-group
            :state="errors"
            label="Note"
            label-for="finish"
            invalid-feedback="note tidak boleh kosong"
          >
            <validation-provider
              #default="{ errors }"
              name="finish"
              rules="required"
            >
              <b-form-textarea
                id="finish"
                name="finish"
                type="text"
                v-model="form.finishReason"
                :state="errors.length > 0 ? false : null"
                placeholder="note"
                required="required"
              />
              <small class="text-danger">{{ errors[0] || errMessage }}</small>
            </validation-provider>
          </b-form-group>

          <b-row>
            <b-col>
              <b-button
                class="mt-1 mb-2"
                type="submit"
                variant="danger"
                block
                @click="finish()"
              >
                Kirim
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-container>
</template>

<script>
import {
  BImg,
  BContainer,
  BBadge,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BMedia,
  BLink,
  BFormFile,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import { $themeConfig } from "@themeConfig";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Cleave from "vue-cleave-component";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    ToastificationContent,
    BImg,
    BContainer,
    ValidationProvider,
    ValidationObserver,
    BBadge,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
    BMedia,
    BLink,
    flatPickr,
    Cleave,
    vSelect,
    BFormFile,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        code: "",
        startDate: "",
        endDate: "",
        endDateNew: "",
        deliveryDate: "",
        type: "",
        status: null,
        status_name: "",
        mileage: 0,
        price: null,
        selectedUnit: null,
        optionsUnit: [],
        document: null,
        image: {
          payment: null,
          receive: null,
        },
        period: 0,
        driver: 0,
        periodName: "",
        driverName: "",
        deliveryAddress: null,
        pickingAddress: null,
        finishReason: "",
        quotation_id: null,
        cancel_reason: null,
      },
      quotation: {},
      catalog: {
        code: "",
        name: "",
        category: "",
      },
      unit: {
        id: null,
        name: "",
        image: "",
        code: "",
        brand: "",
      },
      customer: {
        name: "",
        email: "",
        phone: "",
        address: "",
      },
      officer: {
        name: "",
        phone: "",
        deliveryDate: "",
      },
      update: {
        unit: null,
        mileage: 0,
      },
      permissions: [],
      logs: [],
      url: "",
      errors: "",
      errMessage: "",
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
      },
      file: null,
      selectedCategory: null,
      optionsCategory: [],
    };
  },
  setup() {
    // App Name
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl,
    };
  },
  watch: {
    "selectedCategory": function (data) {
      console.log("data changed")
      console.log(data)
      if (data != null) {
        // this.trigger(data.value);
        console.log("selected")
        console.log(this.selectedCategory);
        this.getUnit();
      }
    },
  },
  created() {
    this.getAllPermissions();
    this.getCategories();
    this.getDetails();
    // this.getUnit();
  },
  methods: {
    formatRupiah(money) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(money);
    },
    moment(date) {
      return moment(date).format("DD-MM-YYYY, hh:mm");
    },
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getDetails() {
      const id = this.$route.params.id;
      const { baseUrl } = $themeConfig.app;
      const imageUri = baseUrl + "image/contract/";

      this.$http.get("sales/contract/" + id).then((response) => {
        // console.log(response.data.data)
        let $data = response.data.data;
        this.form.code = $data.code;
        this.form.startDate = $data.start_date;
        this.form.endDate = $data.end_date;
        this.form.deliveryDate = $data.delivery_date;
        this.form.status = $data.status;
        this.form.status_name = $data.status_name;
        this.form.price = this.formatRupiah($data.price);
        this.form.type = $data.type;
        this.form.period = $data.period;
        this.form.driver = $data.driver;
        this.form.periodName = $data.period_name;
        this.form.driverName = $data.driver_name;
        this.form.mileage = $data.mileage;
        this.form.image.payment = imageUri + $data.payment_proof;
        this.form.image.receive = imageUri + $data.received_proof;
        this.form.document = $data.document;
        this.form.deliveryAddress = $data.delivery_address;
        this.form.pickingAddress = $data.picking_address;
        this.form.quotationId = $data.quotation_id;
        this.form.cancelReason = $data.cancel_reason;
        this.form.finishReason = $data.finish_reason;

        this.catalog.name = $data.catalog_name;
        this.catalog.code = $data.catalog_code;
        this.catalog.category = $data.category_name;
        this.catalog.categoryId = $data.category_id;
        
        this.selectedCategory = $data.category_id;
        this.getUnit();

        this.officer.name = $data.officer;
        this.officer.phone = $data.officer_phone;

        this.quotation = $data.quotation;

        let $unit = $data.unit;

        if ($unit != null) {
          const { baseUrl } = $themeConfig.app;
          this.url = baseUrl;
          this.url += "image/unit/";

          this.unit.id = $unit.id;
          this.unit.name = $unit.name;
          this.unit.image = this.url + $unit.image;
          this.unit.code = $unit.police_no;
          this.unit.brand = $unit.brand;
        }

        let $customer = $data.customer;
        this.customer.id = $customer.id;
        this.customer.name = $customer.name;
        this.customer.phone = $customer.phone;
        this.customer.email = $customer.email;

        let $address = $data.address;
        this.customer.address =
          $address.address +
          ", " +
          $address.village +
          ", " +
          $address.district +
          ", " +
          $address.city +
          ", " +
          $address.province +
          ", " +
          $address.country +
          " " +
          $address.postal_code;

        this.getLogs();
      });
    },
    getLogs() {
      const id = this.$route.params.id;
      this.$http.get("sales/contract/" + id + "/logs").then((response) => {
        // console.log(response.data.data)
        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          if (i === datas.length - 1) {
            var $data = {
              description: datas[i].description,
              created_at: datas[i].created_at,
              variant: "primary",
            };
            this.logs.push($data);
          } else {
            var $data = {
              description: datas[i].description,
              created_at: datas[i].created_at,
              variant: "dark",
            };
            this.logs.push($data);
          }
        }
      });
    },
    settleContract() {
      const id = this.$route.params.id;
      this.$http
        .post("sales/contract/" + id + "/settle", {
          end_date: this.form.endDate,
          start_date: this.form.startDate,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Kontrak berhasil dibuat",
              variant: "success",
            },
          });
          location.href = "/contract/" + id;
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    confirmPayment() {
      const id = this.$route.params.id;
      this.$http
        .post("sales/contract/" + id + "/payment-confirm")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Konfirmasi Pembayaran Berhasil",
              variant: "success",
            },
          });
          location.href = "/contract/" + id;
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    process() {
      const id = this.$route.params.id;
      this.$http
        .post("sales/contract/" + id + "/process", {
          officer: this.officer.name,
          officer_phone: this.officer.phone,
          delivery_date: this.officer.deliveryDate,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Kontrak diproses",
              variant: "success",
            },
          });
          location.href = "/contract/" + id;
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    cancel() {
      const id = this.$route.params.id;
      this.$http
        .post("sales/contract/" + id + "/cancel", {
          cancel_reason: this.form.cancelReason,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Kontrak berhasil dibatalkan",
              variant: "success",
            },
          });
          location.href = "/contracts";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    finish() {
      const id = this.$route.params.id;
      this.$http
        .post("sales/contract/" + id + "/finish", {
          finish_reason: this.form.finishReason,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Kontrak berhasil diakhiri",
              variant: "success",
            },
          });
          location.href = "/contracts";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    sendReminder() {
      const id = this.$route.params.id;
      this.$http
        .get("sales/contract/" + id + "/reminder")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Pengingat berhasil dikirim",
              variant: "success",
            },
          });
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    getCategories() {
      this.$http.get("unit/categories/all").then((response) => {
        // console.log(response.data.data)
        this.optionsCategory.push({
          value: null,
          text: "Please select 1 Category",
        });

        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          // console.log(datas[1].children.length === 0)
          if (datas[i].children.length === 0) {
            var $data = {
              value: datas[i].id,
              text: datas[i].name,
            };
            // console.log($data)
            this.optionsCategory.push($data);
          } else {
            var $dataParent = {
              value: datas[i].id,
              text: datas[i].name,
              disabled: true,
            };
            this.optionsCategory.push($dataParent);

            var j;
            var datas2 = datas[i].children;
            // console.log(datas2[i].name)
            for (j = 0; j < datas2.length; j++) {
              var $dataChildren = {
                value: datas2[j].id,
                text: datas[i].name + " - " + datas2[j].name,
              };
              this.optionsCategory.push($dataChildren);
            }
          }
        }
        // console.log(this.form.optionsCategory)
      });
    },
    getUnit() {
      console.log("getUnit - " + this.selectedCategory);
      this.$http.get("units?status=1" + "&category=" + this.selectedCategory).then((response) => {

        this.form.optionsUnit = []
        
        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          var $data = {
            value: datas[i].id,
            text: "(" + datas[i].police_no + ") " + datas[i].name,
          };
          this.form.optionsUnit.push($data);
        }

        console.log(datas);
      });
    },
    storeUnit() {
      const id = this.$route.params.id;
      var newMileage = this.update.mileage;

      if (newMileage != null) {
        newMileage = newMileage.replace(/\,/g, "");
      }
      this.$http
        .post("sales/contract/" + id + "/unit", {
          unit: this.update.unit.value,
          mileage: newMileage,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Unit telah ditambahkan",
              variant: "success",
            },
          });
          location.href = "/contract/" + id;
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    storeDocument() {
      const id = this.$route.params.id;
      const formData = new FormData();
      formData.append("file", this.file);

      this.$http
        .post("sales/contract/" + id + "/document", formData)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Upload Document Berhasil",
              variant: "success",
            },
          });
          location.href = "/contract/" + id;
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    openFile() {
      const { baseUrl } = $themeConfig.app;
      const fileUri = baseUrl + "file/contract/";
      var uri = fileUri + this.form.document;
      window.open(uri, "_blank", "noreferrer");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>